<template>
	<div class="center-text" style="padding: 10px;">
	  <h1 class="center-text full-top">
		  <b>{{lang[this.appLang].update_p1}}</b>
	  </h1>
	  <p class="center-text half-top">{{lang[this.appLang].update_p3}}</p>
	  <br>
	  <br>
	  <a href="https://tapni.co/app" style="z-index: 999; margin: 0;" class="button white-button">
		  {{lang[this.appLang].update_p2}}
	  </a>
	</div>
</template>

<script>
  /* eslint-disable */
  import {mapState} from 'vuex'

  export default {
  name: 'ErroUpdate',
  data () {
    return {
		versionInterval: null
    }
  },
  computed: {
    ...mapState(['isOutdatedVersion'])
  },
  methods: {
    init () {
      this.versionInterval = setInterval(this.maintenance, 2000)

      if (!this.isOutdatedVersion) {
        clearInterval(this.versionInterval)
        this.$router.push('/welcome')
      }
      this.$store.commit('setInitialize', true)
      this.$store.commit('setLoading', false)
    }
  },
  mounted () {
    if (this.route) this.init()
  },
  watch: {
    'isMaintenance' () {
      this.init()
    },
    'route.name' (nv, ov) {
      if (!ov && nv) this.init()
    },
  }
}
</script>

<style scoped>
</style>
